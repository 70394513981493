export const PAYS = [
  { code: "AF", label: "AFGHANISTAN", value: "AFGHANISTAN", libelle: "ÉTAT ISLAMIQUE D'AFGHANISTAN", actif: "Oui" },
  {
    code: "ZA",
    label: "AFRIQUE DU SUD",
    value: "AFRIQUE DU SUD",
    libelle: "RÉPUBLIQUE D'AFRIQUE DU SUD",
    actif: "Oui",
  },
  { code: "AL", label: "ALBANIE", value: "ALBANIE", libelle: "RÉPUBLIQUE D'ALBANIE", actif: "Oui" },
  {
    code: "DZ",
    label: "ALGERIE",
    value: "ALGERIE",
    libelle: "RÉPUBLIQUE ALGÉRIENNE DÉMOCRATIQUE ET POPULAIRE",
    actif: "Oui",
  },
  { code: "DE", label: "ALLEMAGNE", value: "ALLEMAGNE", libelle: "RÉPUBLIQUE FÉDÉRALE D'ALLEMAGNE", actif: "Oui" },
  { code: "AD", label: "ANDORRE", value: "ANDORRE", libelle: "PRINCIPAUTÉ D'ANDORRE", actif: "Oui" },
  { code: "AO", label: "ANGOLA", value: "ANGOLA", libelle: "RÉPUBLIQUE D'ANGOLA", actif: "Oui" },
  { code: "AG", label: "ANTIGUA-ET-BARBUDA", value: "ANTIGUA-ET-BARBUDA", libelle: "ANTIGUA-ET-BARBUDA", actif: "Oui" },
  {
    code: "SA",
    label: "ARABIE SAOUDITE",
    value: "ARABIE SAOUDITE",
    libelle: "ROYAUME D'ARABIE SAOUDITE",
    actif: "Oui",
  },
  { code: "AR", label: "ARGENTINE", value: "ARGENTINE", libelle: "RÉPUBLIQUE ARGENTINE", actif: "Oui" },
  { code: "AM", label: "ARMENIE", value: "ARMENIE", libelle: "RÉPUBLIQUE D'ARMÉNIE", actif: "Oui" },
  { code: "AU", label: "AUSTRALIE", value: "AUSTRALIE", libelle: "AUSTRALIE", actif: "Oui" },
  { code: "AT", label: "AUTRICHE", value: "AUTRICHE", libelle: "RÉPUBLIQUE D'AUTRICHE", actif: "Oui" },
  { code: "AZ", label: "AZERBAIDJAN", value: "AZERBAIDJAN", libelle: "RÉPUBLIQUE AZERBAÏDJANAISE", actif: "Oui" },
  { code: "BS", label: "BAHAMAS", value: "BAHAMAS", libelle: "COMMONWEALTH DES BAHAMAS", actif: "Oui" },
  { code: "BH", label: "BAHREIN", value: "BAHREIN", libelle: "ÉTAT DE BAHREÏN", actif: "Oui" },
  { code: "BD", label: "BANGLADESH", value: "BANGLADESH", libelle: "RÉPUBLIQUE POPULAIRE DU BANGLADESH", actif: "Oui" },
  { code: "BB", label: "BARBADE", value: "BARBADE", libelle: "BARBADE", actif: "Oui" },
  { code: "BE", label: "BELGIQUE", value: "BELGIQUE", libelle: "ROYAUME DE BELGIQUE", actif: "Oui" },
  { code: "BZ", label: "BELIZE", value: "BELIZE", libelle: "BELIZE", actif: "Oui" },
  { code: "BJ", label: "BENIN", value: "BENIN", libelle: "RÉPUBLIQUE DU BÉNIN", actif: "Oui" },
  { code: "BT", label: "BHOUTAN", value: "BHOUTAN", libelle: "ROYAUME DU BHOUTAN", actif: "Oui" },
  { code: "BY", label: "BIELORUSSIE", value: "BIELORUSSIE", libelle: "RÉPUBLIQUE DE BIÉLORUSSIE", actif: "Oui" },
  { code: "MM", label: "BIRMANIE", value: "BIRMANIE", libelle: "UNION DE BIRMANIE", actif: "Oui" },
  { code: "BO", label: "BOLIVIE", value: "BOLIVIE", libelle: "ÉTAT PLURINATIONAL DE BOLIVIE", actif: "Oui" },
  {
    code: "BQ",
    label: "BONAIRE, SAINT EUSTACHE ET SABA",
    value: "BONAIRE, SAINT EUSTACHE ET SABA",
    libelle: "BONAIRE, SAINT EUSTACHE ET SABA",
    actif: "Oui",
  },
  {
    code: "BA",
    label: "BOSNIE-HERZEGOVINE",
    value: "BOSNIE-HERZEGOVINE",
    libelle: "RÉPUBLIQUE DE BOSNIE-HERZÉGOVINE",
    actif: "Oui",
  },
  { code: "BW", label: "BOTSWANA", value: "BOTSWANA", libelle: "RÉPUBLIQUE DU BOTSWANA", actif: "Oui" },
  { code: "BR", label: "BRESIL", value: "BRESIL", libelle: "RÉPUBLIQUE FÉDÉRATIVE DU BRÉSIL", actif: "Oui" },
  { code: "BN", label: "BRUNEI", value: "BRUNEI", libelle: "NEGARA BRUNEI DARUSSALAM", actif: "Oui" },
  { code: "BG", label: "BULGARIE", value: "BULGARIE", libelle: "RÉPUBLIQUE DE BULGARIE", actif: "Oui" },
  { code: "BF", label: "BURKINA", value: "BURKINA", libelle: "BURKINA FASO", actif: "Oui" },
  { code: "BI", label: "BURUNDI", value: "BURUNDI", libelle: "RÉPUBLIQUE DU BURUNDI", actif: "Oui" },
  { code: "KH", label: "CAMBODGE", value: "CAMBODGE", libelle: "ROYAUME DU CAMBODGE", actif: "Oui" },
  { code: "CM", label: "CAMEROUN", value: "CAMEROUN", libelle: "RÉPUBLIQUE DU CAMEROUN", actif: "Oui" },
  { code: "CA", label: "CANADA", value: "CANADA", libelle: "CANADA", actif: "Oui" },
  { code: "CV", label: "CAP-VERT", value: "CAP-VERT", libelle: "RÉPUBLIQUE DU CAP-VERT", actif: "Oui" },
  {
    code: "CF",
    label: "CENTRAFRICAINE (REPUBLIQUE)",
    value: "CENTRAFRICAINE (REPUBLIQUE)",
    libelle: "RÉPUBLIQUE CENTRAFRICAINE",
    actif: "Oui",
  },
  { code: "CL", label: "CHILI", value: "CHILI", libelle: "RÉPUBLIQUE DU CHILI", actif: "Oui" },
  { code: "CN", label: "CHINE", value: "CHINE", libelle: "RÉPUBLIQUE POPULAIRE DE CHINE", actif: "Oui" },
  { code: "CY", label: "CHYPRE", value: "CHYPRE", libelle: "RÉPUBLIQUE DE CHYPRE", actif: "Oui" },
  { code: "CO", label: "COLOMBIE", value: "COLOMBIE", libelle: "RÉPUBLIQUE DE COLOMBIE", actif: "Oui" },
  {
    code: "KM",
    label: "COMORES",
    value: "COMORES",
    libelle: "RÉPUBLIQUE FÉDÉRALE ISLAMIQUE DES COMORES",
    actif: "Oui",
  },
  { code: "CG", label: "CONGO", value: "CONGO", libelle: "RÉPUBLIQUE DU CONGO", actif: "Oui" },
  {
    code: "CD",
    label: "CONGO (REPUBLIQUE DEMOCRATIQUE)",
    value: "CONGO (REPUBLIQUE DEMOCRATIQUE)",
    libelle: "RÉPUBLIQUE DÉMOCRATIQUE DU CONGO",
    actif: "Oui",
  },
  {
    code: "KR",
    label: "COREE (REPUBLIQUE DE)",
    value: "COREE (REPUBLIQUE DE)",
    libelle: "RÉPUBLIQUE DE CORÉE",
    actif: "Oui",
  },
  {
    code: "KP",
    label: "COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)",
    value: "COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)",
    libelle: "RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE CORÉE",
    actif: "Oui",
  },
  { code: "CR", label: "COSTA RICA", value: "COSTA RICA", libelle: "RÉPUBLIQUE DU COSTA RICA", actif: "Oui" },
  { code: "CI", label: "COTE D'IVOIRE", value: "COTE D'IVOIRE", libelle: "RÉPUBLIQUE DE CÔTE D'IVOIRE", actif: "Oui" },
  { code: "HR", label: "CROATIE", value: "CROATIE", libelle: "RÉPUBLIQUE DE CROATIE", actif: "Oui" },
  { code: "CU", label: "CUBA", value: "CUBA", libelle: "RÉPUBLIQUE DE CUBA", actif: "Oui" },
  { code: "CW", label: "CURAÇAO", value: "CURAÇAO", libelle: "CURAÇAO", actif: "Oui" },
  { code: "DK", label: "DANEMARK", value: "DANEMARK", libelle: "ROYAUME DU DANEMARK", actif: "Oui" },
  { code: "DJ", label: "DJIBOUTI", value: "DJIBOUTI", libelle: "RÉPUBLIQUE DE DJIBOUTI", actif: "Oui" },
  {
    code: "DO",
    label: "DOMINICAINE (REPUBLIQUE)",
    value: "DOMINICAINE (REPUBLIQUE)",
    libelle: "RÉPUBLIQUE DOMINICAINE",
    actif: "Oui",
  },
  { code: "DM", label: "DOMINIQUE", value: "DOMINIQUE", libelle: "COMMONWEALTH DE DOMINIQUE", actif: "Oui" },
  { code: "EG", label: "EGYPTE", value: "EGYPTE", libelle: "RÉPUBLIQUE ARABE D'ÉGYPTE", actif: "Oui" },
  { code: "SV", label: "EL SALVADOR", value: "EL SALVADOR", libelle: "RÉPUBLIQUE DU SALVADOR", actif: "Oui" },
  {
    code: "AE",
    label: "EMIRATS ARABES UNIS",
    value: "EMIRATS ARABES UNIS",
    libelle: "ÉMIRATS ARABES UNIS",
    actif: "Oui",
  },
  { code: "EC", label: "EQUATEUR", value: "EQUATEUR", libelle: "RÉPUBLIQUE DE L'ÉQUATEUR", actif: "Oui" },
  { code: "ER", label: "ERYTHREE", value: "ERYTHREE", libelle: "ÉTAT D'ÉRYTHRÉE", actif: "Oui" },
  { code: "ES", label: "ESPAGNE", value: "ESPAGNE", libelle: "ROYAUME D'ESPAGNE", actif: "Oui" },
  { code: "EE", label: "ESTONIE", value: "ESTONIE", libelle: "RÉPUBLIQUE D'ESTONIE", actif: "Oui" },
  { code: "SZ", label: "ESWATINI", value: "ESWATINI", libelle: "ROYAUME D'ESWATINI", actif: "Oui" },
  { code: "US", label: "ETATS-UNIS", value: "ETATS-UNIS", libelle: "ÉTATS-UNIS D'AMÉRIQUE", actif: "Oui" },
  {
    code: "ET",
    label: "ETHIOPIE",
    value: "ETHIOPIE",
    libelle: "RÉPUBLIQUE DÉMOCRATIQUE FÉDÉRALE D'ÉTHIOPIE",
    actif: "Oui",
  },
  {
    code: "MK",
    label: "EX-REPUBLIQUE YOUGOSLAVE DE MACEDOINE",
    value: "EX-REPUBLIQUE YOUGOSLAVE DE MACEDOINE",
    libelle: "EX-RÉPUBLIQUE YOUGOSLAVE DE MACÉDOINE",
    actif: "Oui",
  },
  { code: "FJ", label: "FIDJI", value: "FIDJI", libelle: "RÉPUBLIQUE DES FIDJI", actif: "Oui" },
  { code: "FI", label: "FINLANDE", value: "FINLANDE", libelle: "RÉPUBLIQUE DE FINLANDE", actif: "Oui" },
  { code: "FR", label: "FRANCE", value: "FRANCE", libelle: "RÉPUBLIQUE FRANÇAISE", actif: "Oui" },
  { code: "GA", label: "GABON", value: "GABON", libelle: "RÉPUBLIQUE GABONAISE", actif: "Oui" },
  { code: "GM", label: "GAMBIE", value: "GAMBIE", libelle: "RÉPUBLIQUE DE GAMBIE", actif: "Oui" },
  { code: "GE", label: "GEORGIE", value: "GEORGIE", libelle: "RÉPUBLIQUE DE GÉORGIE", actif: "Oui" },
  { code: "GH", label: "GHANA", value: "GHANA", libelle: "RÉPUBLIQUE DU GHANA", actif: "Oui" },
  { code: "GR", label: "GRECE", value: "GRECE", libelle: "RÉPUBLIQUE HELLÉNIQUE", actif: "Oui" },
  { code: "GD", label: "GRENADE", value: "GRENADE", libelle: "GRENADE", actif: "Oui" },
  { code: "GT", label: "GUATEMALA", value: "GUATEMALA", libelle: "RÉPUBLIQUE DU GUATEMALA", actif: "Oui" },
  { code: "GN", label: "GUINEE", value: "GUINEE", libelle: "RÉPUBLIQUE DE GUINÉE", actif: "Oui" },
  {
    code: "GQ",
    label: "GUINEE EQUATORIALE",
    value: "GUINEE EQUATORIALE",
    libelle: "RÉPUBLIQUE DE GUINÉE ÉQUATORIALE",
    actif: "Oui",
  },
  { code: "GW", label: "GUINEE-BISSAU", value: "GUINEE-BISSAU", libelle: "RÉPUBLIQUE DE GUINÉE-BISSAU", actif: "Oui" },
  { code: "GY", label: "GUYANA", value: "GUYANA", libelle: "RÉPUBLIQUE COOPÉRATIVE DE GUYANA", actif: "Oui" },
  { code: "HT", label: "HAITI", value: "HAITI", libelle: "RÉPUBLIQUE D'HAÏTI", actif: "Oui" },
  { code: "HN", label: "HONDURAS", value: "HONDURAS", libelle: "RÉPUBLIQUE DU HONDURAS", actif: "Oui" },
  { code: "HU", label: "HONGRIE", value: "HONGRIE", libelle: "RÉPUBLIQUE DE HONGRIE", actif: "Oui" },
  { code: "IN", label: "INDE", value: "INDE", libelle: "RÉPUBLIQUE DE L'INDE", actif: "Oui" },
  { code: "ID", label: "INDONESIE", value: "INDONESIE", libelle: "RÉPUBLIQUE D'INDONÉSIE", actif: "Oui" },
  { code: "IR", label: "IRAN", value: "IRAN", libelle: "RÉPUBLIQUE ISLAMIQUE D'IRAN", actif: "Oui" },
  { code: "IQ", label: "IRAQ", value: "IRAQ", libelle: "RÉPUBLIQUE D'IRAQ", actif: "Oui" },
  { code: "IE", label: "IRLANDE, ou EIRE", value: "IRLANDE, ou EIRE", libelle: "IRLANDE", actif: "Oui" },
  { code: "IS", label: "ISLANDE", value: "ISLANDE", libelle: "RÉPUBLIQUE D'ISLANDE", actif: "Oui" },
  { code: "IL", label: "ISRAEL", value: "ISRAEL", libelle: "ÉTAT D'ISRAËL", actif: "Oui" },
  { code: "IT", label: "ITALIE", value: "ITALIE", libelle: "RÉPUBLIQUE ITALIENNE", actif: "Oui" },
  { code: "JM", label: "JAMAIQUE", value: "JAMAIQUE", libelle: "JAMAÏQUE", actif: "Oui" },
  { code: "JP", label: "JAPON", value: "JAPON", libelle: "JAPON", actif: "Oui" },
  { code: "JO", label: "JORDANIE", value: "JORDANIE", libelle: "ROYAUME HACHÉMITE DE JORDANIE", actif: "Oui" },
  { code: "KZ", label: "KAZAKHSTAN", value: "KAZAKHSTAN", libelle: "RÉPUBLIQUE DU KAZAKHSTAN", actif: "Oui" },
  { code: "KE", label: "KENYA", value: "KENYA", libelle: "RÉPUBLIQUE DU KENYA", actif: "Oui" },
  { code: "KG", label: "KIRGHIZISTAN", value: "KIRGHIZISTAN", libelle: "RÉPUBLIQUE KIRGHIZE", actif: "Oui" },
  { code: "KI", label: "KIRIBATI", value: "KIRIBATI", libelle: "RÉPUBLIQUE DE KIRIBATI", actif: "Oui" },
  { code: "XK", label: "KOSOVO", value: "KOSOVO", libelle: "RÉPUBLIQUE DU KOSOVO", actif: "Oui" },
  { code: "KW", label: "KOWEIT", value: "KOWEIT", libelle: "ÉTAT DU KOWEÏT", actif: "Oui" },
  { code: "LA", label: "LAOS", value: "LAOS", libelle: "RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE LAO", actif: "Oui" },
  { code: "LS", label: "LESOTHO", value: "LESOTHO", libelle: "ROYAUME DU LESOTHO", actif: "Oui" },
  { code: "LV", label: "LETTONIE", value: "LETTONIE", libelle: "RÉPUBLIQUE DE LETTONIE", actif: "Oui" },
  { code: "LB", label: "LIBAN", value: "LIBAN", libelle: "RÉPUBLIQUE LIBANAISE", actif: "Oui" },
  { code: "LR", label: "LIBERIA", value: "LIBERIA", libelle: "RÉPUBLIQUE DU LIBERIA", actif: "Oui" },
  {
    code: "LY",
    label: "LIBYE",
    value: "LIBYE",
    libelle: "JAMAHIRIYA ARABE LIBYENNE POPULAIRE ET SOCIALISTE",
    actif: "Oui",
  },
  { code: "LI", label: "LIECHTENSTEIN", value: "LIECHTENSTEIN", libelle: "PRINCIPAUTÉ DE LIECHTENSTEIN", actif: "Oui" },
  { code: "LT", label: "LITUANIE", value: "LITUANIE", libelle: "RÉPUBLIQUE DE LITUANIE", actif: "Oui" },
  { code: "LU", label: "LUXEMBOURG", value: "LUXEMBOURG", libelle: "GRAND-DUCHÉ DE LUXEMBOURG", actif: "Oui" },
  { code: "MG", label: "MADAGASCAR", value: "MADAGASCAR", libelle: "RÉPUBLIQUE DE MADAGASCAR", actif: "Oui" },
  { code: "MY", label: "MALAISIE", value: "MALAISIE", libelle: "MALAISIE", actif: "Oui" },
  { code: "MW", label: "MALAWI", value: "MALAWI", libelle: "RÉPUBLIQUE DU MALAWI", actif: "Oui" },
  { code: "MV", label: "MALDIVES", value: "MALDIVES", libelle: "RÉPUBLIQUE DES MALDIVES", actif: "Oui" },
  { code: "ML", label: "MALI", value: "MALI", libelle: "RÉPUBLIQUE DU MALI", actif: "Oui" },
  { code: "MT", label: "MALTE", value: "MALTE", libelle: "RÉPUBLIQUE DE MALTE", actif: "Oui" },
  { code: "MA", label: "MAROC", value: "MAROC", libelle: "ROYAUME DU MAROC", actif: "Oui" },
  {
    code: "MH",
    label: "MARSHALL (ILES)",
    value: "MARSHALL (ILES)",
    libelle: "RÉPUBLIQUE DES ÎLES MARSHALL",
    actif: "Oui",
  },
  { code: "MU", label: "MAURICE", value: "MAURICE", libelle: "RÉPUBLIQUE DE MAURICE", actif: "Oui" },
  { code: "MR", label: "MAURITANIE", value: "MAURITANIE", libelle: "RÉPUBLIQUE ISLAMIQUE DE MAURITANIE", actif: "Oui" },
  { code: "MX", label: "MEXIQUE", value: "MEXIQUE", libelle: "ÉTATS-UNIS DU MEXIQUE", actif: "Oui" },
  {
    code: "FM",
    label: "MICRONESIE (ETATS FEDERES DE)",
    value: "MICRONESIE (ETATS FEDERES DE)",
    libelle: "ÉTATS FÉDÉRÉS DE MICRONÉSIE",
    actif: "Oui",
  },
  { code: "MD", label: "MOLDAVIE", value: "MOLDAVIE", libelle: "RÉPUBLIQUE DE MOLDAVIE", actif: "Oui" },
  { code: "MC", label: "MONACO", value: "MONACO", libelle: "PRINCIPAUTÉ DE MONACO", actif: "Oui" },
  { code: "MN", label: "MONGOLIE", value: "MONGOLIE", libelle: "MONGOLIE", actif: "Oui" },
  { code: "ME", label: "MONTENEGRO", value: "MONTENEGRO", libelle: "RÉPUBLIQUE DU MONTÉNÉGRO", actif: "Oui" },
  { code: "MZ", label: "MOZAMBIQUE", value: "MOZAMBIQUE", libelle: "RÉPUBLIQUE DU MOZAMBIQUE", actif: "Oui" },
  { code: "NA", label: "NAMIBIE", value: "NAMIBIE", libelle: "RÉPUBLIQUE DE NAMIBIE", actif: "Oui" },
  { code: "NR", label: "NAURU", value: "NAURU", libelle: "RÉPUBLIQUE DE NAURU", actif: "Oui" },
  { code: "NP", label: "NEPAL", value: "NEPAL", libelle: "ROYAUME DU NÉPAL", actif: "Oui" },
  { code: "NI", label: "NICARAGUA", value: "NICARAGUA", libelle: "RÉPUBLIQUE DU NICARAGUA", actif: "Oui" },
  { code: "NE", label: "NIGER", value: "NIGER", libelle: "RÉPUBLIQUE DU NIGER", actif: "Oui" },
  { code: "NG", label: "NIGERIA", value: "NIGERIA", libelle: "RÉPUBLIQUE FÉDÉRALE DU NIGÉRIA", actif: "Oui" },
  { code: "NO", label: "NORVEGE", value: "NORVEGE", libelle: "ROYAUME DE NORVÈGE", actif: "Oui" },
  { code: "NZ", label: "NOUVELLE-ZELANDE", value: "NOUVELLE-ZELANDE", libelle: "NOUVELLE-ZÉLANDE", actif: "Oui" },
  { code: "OM", label: "OMAN", value: "OMAN", libelle: "SULTANAT D'OMAN", actif: "Oui" },
  { code: "UG", label: "OUGANDA", value: "OUGANDA", libelle: "RÉPUBLIQUE DE L'OUGANDA", actif: "Oui" },
  { code: "UZ", label: "OUZBEKISTAN", value: "OUZBEKISTAN", libelle: "RÉPUBLIQUE D'OUZBÉKISTAN", actif: "Oui" },
  { code: "PK", label: "PAKISTAN", value: "PAKISTAN", libelle: "RÉPUBLIQUE ISLAMIQUE DU PAKISTAN", actif: "Oui" },
  { code: "PW", label: "PALAOS (ILES)", value: "PALAOS (ILES)", libelle: "RÉPUBLIQUE DES ÎLES PALAOS", actif: "Oui" },
  {
    code: "PS",
    label: "PALESTINE (État de)",
    value: "PALESTINE (État de)",
    libelle: "ÉTAT DE PALESTINE",
    actif: "Oui",
  },
  { code: "PA", label: "PANAMA", value: "PANAMA", libelle: "RÉPUBLIQUE DU PANAMA", actif: "Oui" },
  {
    code: "PG",
    label: "PAPOUASIE-NOUVELLE-GUINEE",
    value: "PAPOUASIE-NOUVELLE-GUINEE",
    libelle: "PAPOUASIE-NOUVELLE-GUINÉE",
    actif: "Oui",
  },
  { code: "PY", label: "PARAGUAY", value: "PARAGUAY", libelle: "RÉPUBLIQUE DU PARAGUAY", actif: "Oui" },
  { code: "NL", label: "PAYS-BAS", value: "PAYS-BAS", libelle: "ROYAUME DES PAYS-BAS", actif: "Oui" },
  { code: "PE", label: "PEROU", value: "PEROU", libelle: "RÉPUBLIQUE DU PÉROU", actif: "Oui" },
  { code: "PH", label: "PHILIPPINES", value: "PHILIPPINES", libelle: "RÉPUBLIQUE DES PHILIPPINES", actif: "Oui" },
  { code: "PL", label: "POLOGNE", value: "POLOGNE", libelle: "RÉPUBLIQUE DE POLOGNE", actif: "Oui" },
  { code: "PT", label: "PORTUGAL", value: "PORTUGAL", libelle: "RÉPUBLIQUE PORTUGAISE", actif: "Oui" },
  { code: "QA", label: "QATAR", value: "QATAR", libelle: "ÉTAT DU QATAR", actif: "Oui" },
  { code: "RO", label: "ROUMANIE", value: "ROUMANIE", libelle: "ROUMANIE", actif: "Oui" },
  {
    code: "GB",
    label: "ROYAUME-UNI",
    value: "ROYAUME-UNI",
    libelle: "ROYAUME-UNI DE GRANDE-BRETAGNE ET D'IRLANDE DU NORD",
    actif: "Oui",
  },
  { code: "RU", label: "RUSSIE", value: "RUSSIE", libelle: "FÉDÉRATION DE RUSSIE", actif: "Oui" },
  { code: "RW", label: "RWANDA", value: "RWANDA", libelle: "RÉPUBLIQUE RWANDAISE", actif: "Oui" },
  {
    code: "KN",
    label: "SAINT-CHRISTOPHE-ET-NIEVES",
    value: "SAINT-CHRISTOPHE-ET-NIEVES",
    libelle: "FÉDÉRATION DE SAINT-CHRISTOPHE-ET-NIÉVÈS",
    actif: "Oui",
  },
  { code: "SM", label: "SAINT-MARIN", value: "SAINT-MARIN", libelle: "RÉPUBLIQUE DE SAINT-MARIN", actif: "Oui" },
  {
    code: "SX",
    label: "SAINT-MARTIN (PARTIE NEERLANDAISE)",
    value: "SAINT-MARTIN (PARTIE NEERLANDAISE)",
    libelle: "SAINT-MARTIN (PARTIE NÉERLANDAISE)",
    actif: "Oui",
  },
  {
    code: "VC",
    label: "SAINT-VINCENT-ET-LES GRENADINES",
    value: "SAINT-VINCENT-ET-LES GRENADINES",
    libelle: "SAINT-VINCENT-ET-LES GRENADINES",
    actif: "Oui",
  },
  { code: "LC", label: "SAINTE-LUCIE", value: "SAINTE-LUCIE", libelle: "SAINTE-LUCIE", actif: "Oui" },
  { code: "SB", label: "SALOMON (ILES)", value: "SALOMON (ILES)", libelle: "ÎLES SALOMON", actif: "Oui" },
  {
    code: "WS",
    label: "SAMOA OCCIDENTALES",
    value: "SAMOA OCCIDENTALES",
    libelle: "ÉTAT INDÉPENDANT DES SAMOA OCCIDENTALES",
    actif: "Oui",
  },
  {
    code: "ST",
    label: "SAO TOME-ET-PRINCIPE",
    value: "SAO TOME-ET-PRINCIPE",
    libelle: "RÉPUBLIQUE DÉMOCRATIQUE DE SAO TOMÉ-ET-PRINCIPE",
    actif: "Oui",
  },
  { code: "SN", label: "SENEGAL", value: "SENEGAL", libelle: "RÉPUBLIQUE DU SÉNÉGAL", actif: "Oui" },
  { code: "RS", label: "SERBIE", value: "SERBIE", libelle: "RÉPUBLIQUE DE SERBIE", actif: "Oui" },
  { code: "SC", label: "SEYCHELLES", value: "SEYCHELLES", libelle: "RÉPUBLIQUE DES SEYCHELLES", actif: "Oui" },
  { code: "SL", label: "SIERRA LEONE", value: "SIERRA LEONE", libelle: "RÉPUBLIQUE DE SIERRA LEONE", actif: "Oui" },
  { code: "SG", label: "SINGAPOUR", value: "SINGAPOUR", libelle: "RÉPUBLIQUE DE SINGAPOUR", actif: "Oui" },
  { code: "SK", label: "SLOVAQUIE", value: "SLOVAQUIE", libelle: "RÉPUBLIQUE SLOVAQUE", actif: "Oui" },
  { code: "SI", label: "SLOVENIE", value: "SLOVENIE", libelle: "RÉPUBLIQUE DE SLOVÉNIE", actif: "Oui" },
  { code: "SO", label: "SOMALIE", value: "SOMALIE", libelle: "SOMALIE", actif: "Oui" },
  { code: "SD", label: "SOUDAN", value: "SOUDAN", libelle: "RÉPUBLIQUE DU SOUDAN", actif: "Oui" },
  { code: "SS", label: "SOUDAN DU SUD", value: "SOUDAN DU SUD", libelle: "RÉPUBLIQUE DU SOUDAN DU SUD", actif: "Oui" },
  {
    code: "LK",
    label: "SRI LANKA",
    value: "SRI LANKA",
    libelle: "RÉPUBLIQUE DÉMOCRATIQUE SOCIALISTE DU SRI LANKA",
    actif: "Oui",
  },
  { code: "SE", label: "SUEDE", value: "SUEDE", libelle: "ROYAUME DE SUÈDE", actif: "Oui" },
  { code: "CH", label: "SUISSE", value: "SUISSE", libelle: "CONFÉDÉRATION SUISSE", actif: "Oui" },
  { code: "SR", label: "SURINAME", value: "SURINAME", libelle: "RÉPUBLIQUE DU SURINAME", actif: "Oui" },
  { code: "SY", label: "SYRIE", value: "SYRIE", libelle: "RÉPUBLIQUE ARABE SYRIENNE", actif: "Oui" },
  { code: "TJ", label: "TADJIKISTAN", value: "TADJIKISTAN", libelle: "RÉPUBLIQUE DU TADJIKISTAN", actif: "Oui" },
  { code: "TZ", label: "TANZANIE", value: "TANZANIE", libelle: "RÉPUBLIQUE UNIE DE TANZANIE", actif: "Oui" },
  { code: "TD", label: "TCHAD", value: "TCHAD", libelle: "RÉPUBLIQUE DU TCHAD", actif: "Oui" },
  {
    code: "CZ",
    label: "TCHEQUE (REPUBLIQUE)",
    value: "TCHEQUE (REPUBLIQUE)",
    libelle: "RÉPUBLIQUE TCHÈQUE",
    actif: "Oui",
  },
  { code: "TH", label: "THAILANDE", value: "THAILANDE", libelle: "ROYAUME DE THAÏLANDE", actif: "Oui" },
  {
    code: "TL",
    label: "TIMOR ORIENTAL",
    value: "TIMOR ORIENTAL",
    libelle: "RÉPUBLIQUE DÉMOCRATIQUE DU TIMOR ORIENTAL",
    actif: "Oui",
  },
  { code: "TG", label: "TOGO", value: "TOGO", libelle: "RÉPUBLIQUE TOGOLAISE", actif: "Oui" },
  { code: "TO", label: "TONGA", value: "TONGA", libelle: "ROYAUME DES TONGA", actif: "Oui" },
  {
    code: "TT",
    label: "TRINITE-ET-TOBAGO",
    value: "TRINITE-ET-TOBAGO",
    libelle: "RÉPUBLIQUE DE TRINITÉ-ET-TOBAGO",
    actif: "Oui",
  },
  { code: "TN", label: "TUNISIE", value: "TUNISIE", libelle: "RÉPUBLIQUE TUNISIENNE", actif: "Oui" },
  { code: "TM", label: "TURKMENISTAN", value: "TURKMENISTAN", libelle: "TURKMÉNISTAN", actif: "Oui" },
  { code: "TR", label: "TURQUIE", value: "TURQUIE", libelle: "RÉPUBLIQUE TURQUE", actif: "Oui" },
  { code: "TV", label: "TUVALU", value: "TUVALU", libelle: "TUVALU", actif: "Oui" },
  { code: "UA", label: "UKRAINE", value: "UKRAINE", libelle: "UKRAINE", actif: "Oui" },
  { code: "UY", label: "URUGUAY", value: "URUGUAY", libelle: "RÉPUBLIQUE ORIENTALE DE L'URUGUAY", actif: "Oui" },
  { code: "VU", label: "VANUATU", value: "VANUATU", libelle: "RÉPUBLIQUE DE VANUATU", actif: "Oui" },
  {
    code: "VA",
    label: "VATICAN, ou SAINT-SIEGE",
    value: "VATICAN, ou SAINT-SIEGE",
    libelle: "ÉTAT DE LA CITÉ DU VATICAN",
    actif: "Oui",
  },
  { code: "VE", label: "VENEZUELA", value: "VENEZUELA", libelle: "RÉPUBLIQUE BOLIVARIENNE DU VENEZUELA", actif: "Oui" },
  { code: "VN", label: "VIET NAM", value: "VIET NAM", libelle: "RÉPUBLIQUE SOCIALISTE DU VIÊT NAM", actif: "Oui" },
  { code: "YE", label: "YEMEN", value: "YEMEN", libelle: "RÉPUBLIQUE DU YÉMEN", actif: "Oui" },
  { code: "ZM", label: "ZAMBIE", value: "ZAMBIE", libelle: "RÉPUBLIQUE DE ZAMBIE", actif: "Oui" },
  { code: "ZW", label: "ZIMBABWE", value: "ZIMBABWE", libelle: "RÉPUBLIQUE DU ZIMBABWE", actif: "Oui" },
];

export type IPays = (typeof PAYS)[number];
export type IPaysCode = IPays["code"];

export const PAYS_BY_CODE: Record<IPaysCode, IPays> = PAYS.reduce<Record<IPaysCode, IPays>>((acc, pays) => {
  acc[pays.code] = pays;
  return acc;
}, {});
